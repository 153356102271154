const selection = {
    enabled: true,
    type: 'x',
    fill: {
        color: '#24292e',
        opacity: 0.1
    },
    stroke: {
        width: 1,
        dashArray: 3,
        color: '#24292e',
        opacity: 0.4
    },
    xaxis: {
        min: undefined,
        max: undefined
    },
    yaxis: {
        min: undefined,
        max: undefined
    }
}

export default selection
