<template>
  <v-card flat>
    <v-card-text>
      <apex-chart
        :key="chartKey"
        :height="height - 400"
        :options="ColumnChartOptions"
        :series="seriesData"
        type="bar"
      ></apex-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import {
    updateSeriesWithColors,
    currencyConvert
} from '@/util/functions'
import { ref } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { apiService } from '@/api'
import {
    ColumnChartOptions
} from '@/assets/chartOptions/market'

export default {
    name: 'StackedTimeSeriesMonthlyMfr',
    props: {
        series: {
            type: String,
            default: 'chart5',
        },
    },
    setup() {
        const chartKey = ref(0)
        const seriesData = ref([])
        const { width, height } = useWindowSize()
        const data = ref([])

        return {
            updateSeriesWithColors,
            seriesData,
            chartKey,
            height,
            width,
            ColumnChartOptions,
            data,
        }
    },
    mounted() {
        this.getGenDashData()
        this.$root.$on('refresh-all-charts', async () => {
            this.getGenDashData()
        })
    },
    methods: {
        async getGenDashData() {
            const response = await apiService.getData(this.series)
            this.data = response.data
            this.seriesData = updateSeriesWithColors(this.data)

            // Generate combined data
            const RANData = this.seriesData.find(series => series.name === 'RAN').data
            const TARData = this.seriesData.find(series => series.name === 'TAR').data
            const COMData = RANData.map((value, i) => value + TARData[i])

            // Add TOTAL series to the seriesData
            this.seriesData.push({
                name: 'TOTAL',
                data: COMData,
                fillColor: '#2084f7',
                strokeColor: '#2084f7',
                color: '#2084f7'
            })

            // Set the index of the TOTAL series
            const COMIndex = this.seriesData.findIndex(series => series.name === 'TOTAL')

            this.ColumnChartOptions = {
                ...this.ColumnChartOptions,
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [COMIndex],
                    position: 'top', // top, center, bottom
                    style: {
                        colors: ['#000000'],

                    },
                    offsetY: -20,
                    colors: ['#000000'],
                    formatter(val) {
                        return currencyConvert(val)
                    },
                },
                xaxis: {
                    categories: [...new Set(response.xValues)],
                },
                tooltip: {
                    y: {
                        formatter(value) {
                            return currencyConvert(value)
                        },
                    },
                },
            }

            this.chartKey += 1
        }

    },
}
</script>
